/**
 * @fileoverview
 * Vuetify Plugin Configuration
 *
 * For limitations see:
 * https://vuetifyjs.com/en/features/treeshaking/#limitations
 */

import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: true,  //you don't actually need this line as it's for default
    themes: {
      dark: {
        primary: '#410099',
        secondary: '#00A5EF',
        accent: '#ffc72c',
        error: '#e30046',
        success: '#00c7b1',
        info: '#00A5EF',
        warning: '#e30046',
        white:'#FFFFFF',
        black: '#000000'
      },
    }
  }
});
