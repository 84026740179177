import rootConfig from './config/index.json';
import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import CmsClient from '@funimation/cms-client';
import GoogleAuth from '@funimation/comp-google-auth';
import CatalogEditor from '../shared/CatalogEditor';
import { sync } from 'vuex-router-sync';

const env = ({
  'admin-panel.funimation.com': 'prd',
  'cms.prd.funimation.com': 'prd',
  'cms.stg.funimation.com': 'stg',
  'cms.dev.funimation.com': 'dev',
} as Record<string, string>)[window.location.host as string] || 'sbx';

const config = (rootConfig as Record<string, any>)[env];

store.commit('SET_BASE_URL', config.services.BroadcastService.httpUrl);

Vue.use(Vuex);

sync(store, router);

Vue.use(
  GoogleAuth(
    store,
    config.services.Identity.gsuite,
  )
);


Vue.use(CatalogEditor({}, store));
Vue.use(new CmsClient(config.services.BroadcastService, store));

Vue.config.productionTip = false;

const root = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h: any) => h(App),
}).$mount('#app');

(root as any).vue = Vue;
