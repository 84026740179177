import CatalogEditor from './CatalogEditor.vue';
import TitleEditor from './TitleEditor.vue';
import AvailabilityEditor from './AvailabilityEditor.vue';
import AssetEditor from './AssetEditor.vue';
import VideoEditor from './VideoEditor.vue';
import config from './config';
export default function CatalogEditorStore(cfg: any, context: any) {
  const fetchOptions = {
    headers: {
      'x-api-key': config.apiKey,
    },
  };
  return {
    name: 'comp-catalog-editor',
    description: 'Edtior for catalog APIs.',
    install(vue: any) {
      const store = {
        state: {
          asset: null,
          availability: null,
          pkg: null,
          title: null,
          assetLastEvaluatedKey: null,
          availabilityLastEvaluatedKey: null,
          pkgLastEvaluatedKey: null,
          titleLastEvaluatedKey: null,
        },
        actions: {
          async getAvailability(context: any, payload: any) {
            const response = await fetch(config.baseUrl + config.availability
              .replace(/:prefix/, payload.prefix), fetchOptions);
            const json = await response.json();
            context.commit('SET_AVAILABILITY', json);
          },
          async getPkg(context: any, payload: any) {
            const response = await fetch(config.baseUrl + config.package
              .replace(/:prefix/, payload.prefix), fetchOptions);
            const json = await response.json();
            context.commit('SET_PACKAGE', json);
          },
          async getTitle(context: any, payload: any) {
            console.log(config);
            const response = await fetch(config.baseUrl + config.title
              .replace(/:prefix/, payload.prefix), fetchOptions);
            const json = await response.json();
            context.commit('SET_TITLE', json);
          },
          async getAsset(context: any, payload: any) {
            const response = await fetch(config.baseUrl + config.asset
              .replace(/:prefix/, payload.prefix), fetchOptions);
            const json = await response.json();
            context.commit('SET_ASSET', json);
          },
        },
        mutations: {
          SET_ASSET(state: any, payload: any) {
            state.asset = payload;
          },
          SET_AVAILABILITY(state: any, payload: any) {
            state.availability = payload;
          },
          SET_PACKAGE(state: any, payload: any) {
            state.pkg = payload;
          },
          SET_TITLE(state: any, payload: any) {
            state.title = payload;
          },
        },
      };
      context.registerModule('CatalogEditor', store);
      vue.component('catalog-editor', CatalogEditor);
      vue.component('asset-editor', AssetEditor);
      vue.component('title-editor', TitleEditor);
      vue.component('availability-editor', AvailabilityEditor);
      vue.component('video-editor', VideoEditor);
    }
  }
}
