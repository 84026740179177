




























export default {
  name: 'catalog-editor',
  data() {
    return {
      tabs: 0,
    };
  },
}
