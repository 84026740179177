import Vue from 'vue';
import Vuex from 'vuex';

const appState = {
  store: null as any,
};

Vue.use(Vuex);

function clearAndUpper(text: string): string {
  return text.replace(/-/, "").toUpperCase();
}

function toPascalCase(text: string): string {
  return text.replace(/(^\w|-\w)/g, clearAndUpper);
}

const store = {
  state: {
    baseUrl: '',
    user: null,
    error: null,
    errors: [],
  } as any,
  actions: {
    dismissError(context: any) {
      context.commit("DISMISS_ERROR");
    },
    error(context: any, payload: Error) {
      console.error(payload);
      context.commit("ERROR", payload);
    },
    async loadAll(context: any, payload: Record<string, any>): Promise<void> {
      await Promise.all(payload.components.map(async (component: any) => {
        // const baseUrl = context.state.baseUrl;
        // HACK: For some reason the C record DNS version of this endpoint returns "missing auth token" which it should not do
        // this should be investigated and turned back to const baseUrl = context.state.baseUrl;  This fix should work forever.
        const baseUrl = 'https://fryad3s9ce.execute-api.us-west-2.amazonaws.com/prd';
        const src = `${baseUrl}/v1/codeArtifact/pkg/${component.pkg}/${component.version}/package%2F${encodeURIComponent(component.main)}`;
        console.log(src);
        await context.dispatch('loadScript', {
          component,
          src,
        });
        const setup = (window as any)[toPascalCase(component.template)].default;
        const install = setup({
          ...context.state,
        }, appState.store);
        Vue.use(install);
      }));
      console.log(payload.components);
    },
    loadScript(context: any, payload: Record<string, any>): Promise<void> {
      return new Promise((resolve: any, reject: any) => {
        const doc: Document = document;
        const script: HTMLScriptElement = doc.createElement('script');
        (script as any).module = true;
        script.async = false;
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;
        script.src = payload.src;
        doc.body.appendChild(script);
      });
    },
  },
  mutations: {
    SET_BASE_URL(state: any, payload: string) {
      state.baseUrl = payload;
    },
    DISMISS_ERROR(state: any) {
      state.error = null;
    },
    ERROR(state: any, payload: Error) {
      state.error = payload;
      state.errors.push(payload);
    },
  }
};
appState.store = new Vuex.Store<any>(store);
export default appState.store;
