





















import {mapState, mapActions} from 'vuex';
export default {
  name: 'asset-editor',
  data() {
    return {
      tabs: 0,
      assetPrefix: ''
    };
  },
  methods: {
    ...mapActions([
      'getAsset',
      'getAvailability',
      'getPkg',
      'getTitle',
    ]),
  },
  computed: {
    ...mapState({
      asset: (state: Record<string, any>) => state.CatalogEditor.asset,
      availability: (state: Record<string, any>) => state.CatalogEditor.availability,
      pkg: (state: Record<string, any>) => state.CatalogEditor.pkg,
      title: (state: Record<string, any>) => state.CatalogEditor.title,
    } as any),
  },
}
